<template>
<div class="logistic-dashboard">
    <el-tabs v-model="activeTab" type="card">
    <el-tab-pane label="Заявки" name="requests">
        <div class="requests-section">
        <div class="controls">
          <!-- START CONTROLS -->
            <el-button 
            type="primary" 
            @click="fetchRequests" 
            :loading="loading"
            >
            Обновить данные
            </el-button>
            
            <el-select 
            v-model="selectedCar" 
            placeholder="Выберите машину"
            clearable
            filterable
            >
              <el-option
                  v-for="car in cars"
                  :key="car"
                  :label="`${car.model} (${car.number}) - ${car.driver}`"
                  :value="car"
              />
            </el-select>

            <!-- START FILTERS -->
            <el-select
              v-model="selectedAssigned"
              placeholder="Статус назначения"
              clearable
              style="width: 160px"
            >
              <el-option label="Назначенные" value=1 />
              <el-option label="Неназначенные" value=0 />
            </el-select>

            <el-select
              v-model="selectedLocation"
              placeholder="Фильтр по локациям"
              clearable
              filterable
              style="width: 200px"
            >
              <el-option
                v-for="location in uniqueLocations"
                :key="location"
                :label="location"
                :value="location"
              />
            </el-select>

            <el-input
              v-model="searchContact"
              placeholder="Поиск по контактам"
              clearable
              style="width: 200px"
            />
            <!-- END FILTERS -->
          <!-- END CONTROLS -->
        </div>

        <div class="filter-tags" v-if="activeFilters.length">
          <el-tag
            v-for="(filter, index) in activeFilters"
            :key="index"
            closable
            @close="removeFilter(filter)"
          >
            {{ filter.label }}: {{ filter.value }}
          </el-tag>
        </div>

        <el-table 
            :data="filteredRequests"
            stripe 
            style="width: 100%"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="delivery_id" label="ID" width="80" />
            
            <el-table-column label="Контакты" width="200">
            <template #default="{ row }">
                <div class="contact-info">
                <div>{{ row.pickup_contact_name }}</div>
                <div>{{ row.pickup_contact_phone }}</div>
                <div>{{ row.seller_name }}</div>
                </div>
            </template>
            </el-table-column>

            <el-table-column label="Даты" width="220" sortable :sort-by="row => new Date(row.delivery_date)">
            <template #default="{ row }">
                <div class="date-info">
                <div>Доставка: {{ formatDate(row.delivery_date) }}</div>
                <div>Забор: {{ formatDate(row.pickup_date) }}</div>
                </div>
            </template>
            </el-table-column>

            <el-table-column label="Локации">
            <template #default="{ row }">
                <div class="location-info">
                <div>Отправка: {{ row.dispatch_warehouse_city }}</div>
                <div>Доставка: {{ row.delivery_warehouse_city }}</div>
                <div v-if="row.address">Адрес: {{ row.address }}</div>
                </div>
            </template>
            </el-table-column>

            <el-table-column label="Палетирование">
            <template #default="{ row }">
                <div class="location-info">
                    <p> {{ row.is_custom_pallet ? 'Своё' : 'Да' }} </p>
                </div>
            </template>
            </el-table-column>

            <el-table-column label="Статусы" width="130">
            <template #default="{ row }">
                <div class="status-info">
                <el-tag :type="row.paid ? 'success' : 'danger'" size="small">
                    {{ row.paid ? 'Оплачено' : 'Не оплачено' }}
                </el-tag>
                <el-tag :type="row.accepted ? 'success' : 'warning'" size="small">
                    {{ row.accepted ? 'Принято' : 'В обработке' }}
                </el-tag>
                </div>
            </template>
            </el-table-column>

            <el-table-column label="Действия" width="120">
            <template #default="{ row }">
                <el-button 
                size="small" 
                @click="showDetails(row)"
                >
                <el-icon><List /></el-icon>
                </el-button>
                <el-button 
                type="primary" 
                size="small" 
                @click="assignRequest(row)"
                :disabled="!selectedCar || row.assigned"
                >
                <el-icon><Check /></el-icon>
                </el-button><br>
                <el-button 
                type="primary" 
                size="small" 
                style="width: 88%;"
                @click="sticker('120x75', row.delivery_id, row.tg_user_id)"
                >
                Наклейка
                </el-button>
            </template>
            </el-table-column>
        </el-table>
        </div>
    </el-tab-pane>
    
    <el-tab-pane label="Управление" name="manage">
    <div class="manage-section">
      <div class="driver-schedule">
        <el-row :gutter="20">
          <el-col 
            v-for="car in carsWithDriver" 
            :key="car.id" 
            :xs="24" 
            :sm="12" 
            :md="8" 
            :lg="6"
          >
            <el-card class="car-card" shadow="hover" @click="showSchedule(car)">
              <template #header>
                <div class="car-header">
                  <h3>{{ car.model }}</h3>
                  <el-tag type="info">{{ car.number }}</el-tag>
                </div>
              </template>
              
              <div class="driver-info">
                <div class="driver-details">
                  <div>{{ car.driver.name }}</div>
                  <div class="small-text">ID: {{ car.driver.tg_user_id }}</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>

        <!-- Диалог с заявками -->
        <el-dialog 
          v-model="scheduleDialogVisible" 
          :title="`Расписание: ${selectedCar?.model} (${selectedCar?.number})`"
          width="80%"
        >
          <el-table :data="carRequests" stripe style="width: 100%" v-loading="loadingRequests">
            <el-table-column prop="delivery_id" label="ID заявки" width="100" />
            
            <el-table-column label="Время забора" width="220">
              <template #default="{ row }">
                <div class="time-editable" @click.stop="openTimePicker(row)">
                  {{ row.pickup_date }} <br> {{ row.pickup_time }}
                  <el-icon class="edit-icon"><Edit /></el-icon>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Адрес">
              <template #default="{ row }">
                Город: {{ row.dispatch_warehouse_city }} <br> Адрес: {{ row.address }}
              </template>
            </el-table-column>

            <el-table-column label="Контакты">
              <template #default="{ row }">
                <div class="contact-info">
                  <div>{{ row.pickup_contact_name }}</div>
                  <div>{{ row.pickup_contact_phone }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Статус">
              <template #default="{ row }">
                {{ getPickupStatus(row.pickup_status) }}
              </template>
            </el-table-column>

            <el-table-column label="Действия" width="200">
              <template #default="{ row }">
                <el-button 
                size="small" 
                @click="showDetails(row)"
                >
                <el-icon><List /></el-icon>
                </el-button>

                <el-button 
                size="small"  
                @click="openTimePicker(row)"
                >
                <el-icon><Timer /></el-icon>
                </el-button>

                <el-button 
                size="small" 
                @click="unassignRequest(row)"
                >
                <el-icon><Delete /></el-icon>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-dialog>
      </div>
    </div>
    </el-tab-pane>

    <el-tab-pane label="Машины" name="cars">
      <div class="cars-section">
        <div class="controls">
          <el-button type="primary" @click="showAddCarDialog">
            Добавить машину
          </el-button>

          <el-button 
            type="primary" 
            @click="fetchCars" 
            :loading="loading"
            >
            Обновить данные
          </el-button>
        </div>

        <el-table :data="cars" stripe style="width: 100%">
          <el-table-column prop="model" label="Модель" />
          <el-table-column prop="number" label="Номер" />
          <el-table-column label="Водитель">
            <template #default="{ row }">
              {{ getDriverName(row.driver_id) }}
            </template>
          </el-table-column>
          <el-table-column label="Действия" width="150">
            <template #default="{ row }">
              <el-button size="small" @click="editCar(row)">Изменить</el-button>
              <el-button 
                size="small" 
                type="danger" 
                @click="deleteCar(row.id)"
              >
                Удалить
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-tab-pane>
  </el-tabs>

  <!-- Диалог редактирования времени -->
  <el-dialog
    v-model="timePickerVisible"
    title="Изменить время забора"
  >
    <el-time-picker
      v-model="selectedTime"
      arrow-control
      placeholder="Выберите время"
      value-format="HH:mm:ss"
    />
    <template #footer>
      <el-button @click="timePickerVisible = false">Отмена</el-button>
      <el-button type="primary" @click="saveTime">Сохранить</el-button>
    </template>
  </el-dialog>

  <!-- Диалог добавления/редактирования машины -->
  <el-dialog :title="carDialogTitle" v-model="carDialogVisible">
    <el-form :model="currentCar" label-width="120px">
      <el-form-item label="Модель" required>
        <el-input v-model="currentCar.model" /> 
      </el-form-item>
      <el-form-item label="Номер" required>
        <el-input v-model="currentCar.number" />
      </el-form-item>
      <el-form-item label="Водитель">
        <el-select v-model="currentCar.driver_id" placeholder="Выберите водителя" style="width: 300px;">
          <el-option
            v-for="driver in drivers"
            :key="driver.id"
            :label="driver.name"
            :value="driver.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="carDialogVisible = false">Отмена</el-button>
      <el-button type="primary" @click="saveCar">Сохранить</el-button>
    </template>
  </el-dialog>

  <!-- Диалог подробная инфа о заявки -->
  <el-dialog 
  :title="selectedRequest ? `Заявка #${selectedRequest.delivery_id}` : 'Детали заявки'" 
  v-model="dialogVisible"
  width="60%"
  >
  <div v-if="selectedRequest" class="request-details">
      <div class="detail-item">
      <h3>Информация об упаковке</h3>
      <div class="packaging-list" v-if="selectedRequest.packaging_tree && selectedRequest.packaging_tree.length" 
          v-html="renderPackagingTree(selectedRequest.packaging_tree)">
      </div>
      </div>

      <el-divider />

      <div class="detail-grid">
      <div class="detail-item">
          <h4>Тариф:</h4>
          <div>{{ selectedRequest.tariff_name }}</div>
          <div>{{ selectedRequest.tariff_city }} - {{ selectedRequest.tariff }}</div>
      </div>

      <div class="detail-item">
          <h4>Даты:</h4>
          <div>Доставка: {{ formatDate(selectedRequest.delivery_date) }}</div>
          <div>Забор: {{ formatDate(selectedRequest.pickup_date) }}</div>
      </div>

      <div class="detail-item">
          <h4>Контакты:</h4>
          <div>{{ selectedRequest.pickup_contact_name }}</div>
          <div>{{ selectedRequest.pickup_contact_phone }}</div>
      </div>

      <div class="detail-item">
          <h4>Финансы:</h4>
          <div>Общая сумма: {{ selectedRequest.total_price }} ₽</div>
          <div>Количество: {{ selectedRequest.quantity }}</div>
      </div>
      </div>
  </div>
  </el-dialog>
</div>
</template>

<script>
import { Check, List, Timer, Delete } from '@element-plus/icons-vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'

export default {
name: 'LogisticDashboard',
components: {
    Check,
    List,
    Timer,
    Delete
},

data() {
  return {
    timePickerVisible: false,
    selectedTime: null,
    currentEditingRequest: null,
    scheduleDialogVisible: false,
    carRequests: [],
    loadingRequests: false,
    selectedAssigned: null,
    selectedLocation: null,
    searchContact: '',
    dateSort: 'desc',
    loading: false,
    activeTab: 'requests',
    requests: [],
    selectedRequests: [],
    selectedCar: null,
    dialogVisible: false,
    selectedRequest: null,
    currentUserId: null,
    cars: [],
    drivers: [],
    carDialogVisible: false,
    currentCar: {
        id: null,
        model: '',
        number: '',
        driver_id: null,
        status: 'active'
    },
    carStatuses: [
        { value: 'active', label: 'Активна' },
        { value: 'maintenance', label: 'На обслуживании' },
        { value: 'inactive', label: 'Неактивна' }
    ]
  }
},

mounted() {
    this.currentUserId = this.$store.getters.currentUserId
    this.fetchRequests()
    this.fetchCars()
    this.fetchDrivers()
},

computed: {
  carsWithDriver() {
    return this.cars.map(car => ({
      ...car,
      driver: this.drivers.find(d => d.id === car.driver_id) || {}
    }))
  },
  carDialogTitle() {
      return this.currentCar.id ? 'Редактирование машины' : 'Новая машина'
  },

  uniqueLocations() {
    const locations = new Set()
    this.requests.forEach(request => {
      locations.add(request.dispatch_warehouse_city)
      locations.add(request.delivery_warehouse_city)
    })
    return Array.from(locations)
  },

  filteredRequests() {
    return this.requests
      .filter(request => {
        if (this.selectedAssigned === null) return true
        return String(request.assigned) === this.selectedAssigned
      })
      .filter(request => {
        if (!this.selectedLocation) return true
        return request.dispatch_warehouse_city === this.selectedLocation ||
              request.delivery_warehouse_city === this.selectedLocation
      })
      .filter(request => {
        if (!this.searchContact) return true
        const search = this.searchContact.toLowerCase()
        return (
          request.pickup_contact_name?.toLowerCase().includes(search) ||
          request.pickup_contact_phone?.includes(search) ||
          request.seller_name?.toLowerCase().includes(search)
        )
      })
  },
  activeFilters() {
    const filters = []
    if (this.selectedAssigned) {
      filters.push({
        type: 'selectedAssigned',
        label: 'Статус назначения',
        value: this.selectedAssigned === '1' ? 'Назначенные' : 'Неназначенные'
      })
    }
    if (this.selectedLocation) {
      filters.push({
        type: 'selectedLocation',
        label: 'Локация',
        value: this.selectedLocation
      })
    }
    if (this.searchContact) {
      filters.push({
        type: 'searchContact',
        label: 'Поиск по контактам',
        value: this.searchContact
      })
    }
    return filters
  }
},

methods: {
    async fetchCarWithTime(car) {
      try {
        const response = await axios.get(`/api/requests-by-car.php?id=${car.id}`)
        this.carRequests = response.data.data
          .sort((a, b) => {
            const parseTime = (timeStr) => {
                if (timeStr === null) return 0;
                const [hours, minutes] = timeStr.split(":").map(Number);
                return hours * 60 + minutes;
            };

            return parseTime(a.pickup_time) - parseTime(b.pickup_time);
        });
      } catch (error) {
        this.$message.error('Ошибка загрузки расписания')
      } finally {
        this.loadingRequests = false
      }
    },
    async fetchRequests() {
      try {
          this.loading = true
          const response = await axios.get('/api/load-pickup-requests.php')
          
          this.requests = response.data.requests_with_pickup
      } catch (error) {
          ElMessage.error('Ошибка загрузки заявок: ' + error.message)
      } finally {
          this.loading = false
      }
    },

    async fetchCars() {
      try {
        this.loading = true
        const response = await axios.get('/api/load-cars.php')
        
        this.cars = response.data.data
      } catch (error) {
        ElMessage.error('Ошибка загрузки машин: ' + error.message)
      } finally {
        this.loading = false
      }
    },

    async fetchDrivers() {
      try {
        const response = await axios.get('/api/load-drivers.php')
        this.drivers = response.data.data
      } catch (error) {
        ElMessage.error('Ошибка загрузки водителей: ' + error.message)
      }
    },

    removeFilter(filter) {
      this[filter.type] = null
    },

    getDriverName(driverId) {
      const driver = this.drivers.find(d => d.id === driverId)
      return driver ? driver.name : 'Не назначен'
    },

    statusTagType(status) {
      switch (status) {
        case 'active': return 'success'
        case 'maintenance': return 'warning'
        default: return 'info'
      }
    },

    statusText(status) {
      return this.carStatuses.find(s => s.value === status)?.label || status
    },

    showAddCarDialog() {
      this.currentCar = {
        id: null,
        model: '',
        number: '',
        driver_id: null,
        status: 'active'
      }
      this.carDialogVisible = true
    },

    editCar(car) {
      this.currentCar = { ...car }
      this.carDialogVisible = true
    },

    async saveCar() {
      try {
        const url = this.currentCar.id 
          ? `/api/manage-cars.php?id=${this.currentCar.id}`
          : '/api/manage-cars.php'
        
        const method = this.currentCar.id ? 'put' : 'post'
        
        await axios[method](url, this.currentCar)
        ElMessage.success('Машина сохранена')
        this.fetchCars()
        this.carDialogVisible = false
      } catch (error) {
        ElMessage.error('Ошибка сохранения: ' + error.message)
      }
    },

    async deleteCar(id) {
      try {
        await this.$confirm('Удалить машину?', 'Подтверждение', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          type: 'warning'
        })
        
        await axios.delete(`/api/cars/${id}`)
        ElMessage.success('Машина удалена')
        this.fetchCars()
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('Ошибка удаления: ' + error.message)
        }
      }
    },

    async assignRequest(request) {
      try {
        const response = await axios.post('/api/assign-request.php', {
          car_id: this.selectedCar.id,
          request_id: request.delivery_id
        })

        if (response.data.status === "success") {
          ElMessage.success(response.data.message)

          const notification_response = await axios.post('/api/send-notification.php', {
            chat_id: this.drivers.filter(driver => {
                      if (this.selectedCar.driver_id == driver.id) return driver.tg_user_id
                    })[0].tg_user_id,
            text: `Новая заявка #${request.delivery_id}\nАдрес: ${request.address}\nДата: ${this.formatDate(request.pickup_date)}`,
            request_id: request.delivery_id
          })
          if (notification_response.data.status === "success") {
            this.openTimePicker(request)
            ElMessage.success(notification_response.data.message)
          } else {
            ElMessage.error(notification_response.data.message)
          }
        } else {
          ElMessage.error(response.data.message)
        }

        await this.fetchRequests()
      } catch (error) {
        ElMessage.error('Ошибка назначения: ' + error.message)
      }
    },
    renderPackagingTree(packagingTree, level = 0) {
        if (!packagingTree || !packagingTree.length) return '';

        return packagingTree.map(pkg => {
            const indent = level * 20;
            if (pkg.price == 0) {
                return `
                    <div class="packaging-item" style="margin-left: ${indent}px">
                        <p>
                            ${pkg.category}<br>
                            (${pkg.dimensions})<br>
                            ${pkg.amount} шт.
                        </p>
                        ${this.renderPackagingTree(pkg.children, level + 1)}
                    </div>
                `;
            } else {
                return `
                    <div class="packaging-item" style="margin-left: ${indent}px">
                        <p>
                            ${pkg.category}<br>
                            (${pkg.dimensions})<br>
                            ${pkg.amount} шт. × 
                            ${pkg.price}₽/шт = 
                            ${pkg.amount * pkg.price}₽
                        </p>
                        ${this.renderPackagingTree(pkg.children, level + 1)}
                    </div>
                `;
            }
        }).join('');
    },

    async unassignRequest(request) {
      try {
        const response = await axios.delete(`/api/unassign-pickup-requests.php`, {
            data: {
              car_id: this.selectedCar.id,
              request_id: request.delivery_id
            }
        });
        this.fetchCarWithTime(this.selectedCar)
        if (response.data.status === "success") {
          const notification_response = await axios.post('/api/send-notification.php', {
            chat_id: this.drivers.filter(driver => {
                      if (this.selectedCar.driver_id == driver.id) return driver.tg_user_id
                    })[0].tg_user_id,
            text: `Удалена заявка #${request.delivery_id}\nАдрес: ${request.address}\nДата: ${this.formatDate(request.pickup_date)}`,
          });

          if (notification_response.data.status === "success") {
            ElMessage.success(notification_response.data.message)
          } else {
            ElMessage.error(notification_response.data.message)
          }
          this.$message.success(response.data.message)
        } else if (response.data.status === "error") {
          this.$message.error(response.data.message)
        }
      } catch (error) {
        this.$message.error('Ошибка удаления заявки')
      }
    },

    openTimePicker(row) {
      this.currentEditingRequest = row
      this.selectedTime = row.pickup_time
      this.timePickerVisible = true
    },

    async saveTime() {
      try {
        const response = await axios.put(`/api/add-time.php`, {
          date: this.selectedTime,
          car_id: this.selectedCar.id,
          req_id: this.currentEditingRequest.delivery_id
        })

        if (response.data.status === "success") {
          this.$message.success(response.data.message)
          const car = this.drivers.filter(driver => {
                      if (this.selectedCar.driver_id == driver.id) return driver
                    })[0]

          const notification_response = await axios.post('/api/send-notification.php', {
            chat_id: car.tg_user_id,
            text:
`Обновлено время забора для заявки #${this.currentEditingRequest.delivery_id} на ${this.selectedTime}
Контакты:
ФИО - ${this.currentEditingRequest.pickup_contact_name}
Телефон - ${this.currentEditingRequest.pickup_contact_phone}
Адрес: ${this.currentEditingRequest.address}`
          })
          if (notification_response.data.status === "success") {
            ElMessage.success(notification_response.data.message)
          } else {
            ElMessage.error(notification_response.data.message)
          }

          this.fetchCarWithTime(this.selectedCar)
        } else {
          this.$message.error(response.data.message)
        }
        
        this.timePickerVisible = false
      } catch (error) {
        this.$message.error('Ошибка: ' + error)
      }
    },

    async showSchedule(car) {
      this.fetchCarWithTime(car)
      this.selectedCar = car
      this.scheduleDialogVisible = true
      this.loadingRequests = true
    },

    formatDate(date) {
    return dayjs(date).format('DD.MM.YYYY')
    },

    showDetails(request) {
    this.selectedRequest = request
    this.dialogVisible = true
    },

    handleSelectionChange(selection) {
    this.selectedRequests = selection
    },

    getPickupStatus(status) {
      switch (status) {
        case "pending":
          return "Не прочитанно"
        case "completed":
          return "Выполненно";
        case "in_progress":
          return "Выполняется";
      }
    },
    sticker (size, r_id, user_id) {
        window.open(`https://test.redbr.ru/api/sticker/?size=${size}&user_id=${user_id}&id=${r_id}`, '_blank');
    },
}
}
</script>

<style scoped>
.logistic-dashboard {
padding: 20px;
}

.contact-info div,
.date-info div,
.location-info div {
margin: 3px 0;
font-size: 0.9em;
}

.status-info {
display: flex;
flex-direction: column;
gap: 5px;
}

.detail-grid {
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 20px;
margin-top: 20px;
}

.request-details .detail-item {
margin-bottom: 20px;
padding: 10px;
background: #f8f8f8;
border-radius: 5px;
}

.el-tag {
margin-right: 5px;
}

.cars-section {
  padding: 20px;
}

.controls {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

.filter-tags {
  margin: 10px 0;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.car-card {
  margin-bottom: 20px;
  cursor: pointer;
  transition: transform 0.3s;
}

.car-card:hover {
  transform: translateY(-5px);
}

.car-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.driver-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.driver-details {
  line-height: 1.4;
}

.small-text {
  font-size: 0.8em;
  color: #666;
}

.time-editable {
  cursor: pointer;
  position: relative;
  padding-right: 25px;
  
  &:hover {
    color: #409EFF;
    
    .edit-icon {
      opacity: 1;
    }
  }
}

.edit-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  transition: opacity 0.3s;
}
</style>