<template>
<div class="manage-requests">
    <el-card>
    <template #header>
        <div class="controls">
            <el-select 
            v-model="selectedDate" 
            placeholder="Дата"
            clearable
            filterable
            >
              <el-option
                  v-for="date in uniqueDates"
                  :key="date"
                  :label="date"
                  :value="date"
              />
            </el-select>

            <el-select 
            v-model="selectedWarehouse" 
            placeholder="Склад"
            clearable
            filterable
            >
              <el-option
                  v-for="warehouse in uniqueWarehouse"
                  :key="warehouse"
                  :label="warehouse"
                  :value="warehouse"
              />
            </el-select>

            <el-select 
            v-model="selectedStreet" 
            placeholder="Улица"
            clearable
            filterable
            >
              <el-option
                  v-for="street in uniqueStreet"
                  :key="street"
                  :label="street"
                  :value="street"
              />
            </el-select>
        </div>
    </template>

    <el-table 
        :data="filteredRequests"
        v-loading="loading"
        style="width: 100%"
        stripe
        :fit="true"
    >
        <el-table-column prop="id" label="ID" width="80" sortable />

        <el-table-column prop="contact_name" label="Имя контакта" width="130" />
        <el-table-column prop="contact_phone" label="Телефон" width="130" />
        <el-table-column prop="seller_name" label="Селлер" width="120" />

        <el-table-column label="Упаковка" width="120">
        <template #default="{ row }">
            {{ row.packing_short_name || 'N/A' }}
        </template>
        </el-table-column>

        <el-table-column prop="quantity" label="Кол-во" width="80" />

        <el-table-column label="Направления" width="220">
        <template #default="{ row }">
            <div class="warehouses">
            <div>Из: {{ formatWarehouse(row.dispatch_city_name, row.dispatch_street) }}</div>
            <div>На: {{ formatWarehouse(row.delivery_city_name, row.delivery_street) }}</div>
            </div>
        </template>
        </el-table-column>

        <el-table-column label="Даты" width="200">
        <template #default="{ row }">
            <div class="dates">
            <div>Доставка: {{ formatDate(row.delivery_date) }}</div>
            <div>Приёмка: {{ formatDate(row.expected_date) }}</div>
            <div>Принят в: {{ formatDate(row.acceptance_time) }}</div>
            <div>Создан: {{ formatDate(row.creation_date) }}</div>
            </div>
        </template>
        </el-table-column>

        <el-table-column label="Статус" width="120">
        <template #default="{ row }">
            <el-tag :type="statusTagType(row.pickup_status)">
            {{ row.accepted ? 'Принят' : 'Не принят' }}
            </el-tag>
        </template>
        </el-table-column>

        <el-table-column label="Оплата" width="100">
        <template #default="{ row }">
            <el-tag :type="row.paid ? 'success' : 'danger'">
            {{ row.paid ? 'Оплачен' : 'Не оплачен' }}
            </el-tag>
        </template>
        </el-table-column>

        <el-table-column label="Цена" width="120">
        <template #default="{ row }">
            ₽{{ parseFloat(row.total_price).toFixed(2) }}
        </template>
        </el-table-column>

        <el-table-column label="Действия" width="120">
        <template #default="{ row }">
            <div class="actions">
            <el-button 
                :disabled="!hasPermission()"
                size="mini" 
                @click="newPayStatus(row.paid, row.id)"
            >{{ !row.paid ? 'Оплачен' : 'Не оплачен' }}</el-button><br>
            <el-button 
                size="mini" 
                @click="sticker('120x75', row.id, row.tg_user_id)"
            >Термонаклейка</el-button>
            </div>
        </template>
        </el-table-column>
    </el-table>
    </el-card>
</div>
</template>

<script>
import { ElTable, ElTableColumn, ElTag, ElCard, ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    components: {
    ElTable,
    ElTableColumn,
    ElTag,
    ElCard
    },
    
    data() {
    return {
        loading: true,
        requests: [],
        selectedDate: null,
        selectedWarehouse: null,
        selectedStreet: null,
    }
    },

    mounted() {
    this.fetchData()
    },

    computed: {
        uniqueDates() {
            const locations = new Set()
            this.requests.forEach(request => {
                locations.add(request.delivery_date)
                locations.add(request.expected_date)
            })
            return Array.from(locations)
        },

        uniqueWarehouse() {
            const locations = new Set()
            this.requests.forEach(request => {
                locations.add(request.delivery_city_name)
                locations.add(request.dispatch_city_name)
            })
            return Array.from(locations)
        },

        uniqueStreet() {
            const locations = new Set()
            this.requests.forEach(request => {
                locations.add(request.delivery_street)
                locations.add(request.dispatch_street)
            })
            return Array.from(locations)
        },

        filteredRequests() {
            return this.requests
                .filter(request => {
                    if (!this.selectedDate) return true
                    return this.selectedDate == request.delivery_date
                        ||
                    this.selectedDate == request.expected_date
                })
                .filter(request => {
                    if (!this.selectedWarehouse) return true
                    return this.selectedWarehouse == request.delivery_city_name
                        ||
                    this.selectedWarehouse == request.dispatch_city_name
                })
                .filter(request => {
                    if (!this.selectedStreet) return true
                    return this.selectedStreet == request.delivery_street
                        ||
                    this.selectedStreet == request.dispatch_street
                })
        },
    },

    methods: {
    hasPermission() {
      return this.$store.getters.userRole === 'accountant' || this.$store.getters.userRole === 'admin_kr7a-J';
    },
    async newPayStatus(status, id) {
        const response = await axios.put(`/api/set-pay-status.php?f=${status ? 0 : 1 }&id=${id}`)
        if (response.data.status === 'success') {
            ElMessage.success(response.data.message)
            this.loading = true
            this.fetchData()
        } else if (response.data.status === 'error') {
            ElMessage.error(response.data.message)
        }
    },
    sticker (size, r_id, user_id) {
        window.open(`https://test.redbr.ru/api/sticker/?size=${size}&user_id=${user_id}&id=${r_id}`, '_blank');
    },
    async fetchData() {
        try {
        const response = await fetch('https://test.redbr.ru/api/get_requests.php')
        const data = await response.json()
        
        if (!data.success) {
            console.error('Error:', data.message)
            return
        }
        
        this.requests = data.data
        
        } catch (error) {
        console.error('Fetch error:', error)
        } finally {
        this.loading = false
        }
    },

    formatWarehouse(city, street) {
        if (!city && !street) return 'N/A'
        return `${city ? city : ''}${street ? ` (${street})` : ''}`
    },
    formatDate(dateStr) {
        if (!dateStr) return 'N/A';

        const date = new Date(dateStr.replace(' ', 'T'));
        
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const timeMatch = dateStr.match(/\d{2}:\d{2}:\d{2}/);
        const time = timeMatch ? ` ${timeMatch[0]}` : '';

        return `${day}.${month}.${year}${time}`;
    },
    statusTagType(status) {
        switch ((status || '').toLowerCase()) {
        case 'completed': return 'success'
        case 'cancelled': return 'danger'
        case 'pending': return 'warning'
        default: return 'info'
        }
    }
    }
}
</script>

<style scoped>
.el-table {
    display: flex;
    justify-content: center;
}

.controls {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

.manage-requests {
padding: 20px;
}

.warehouses div {
line-height: 1.3;
margin: 2px 0;
font-size: 0.9em;
}

.dates div {
line-height: 1.3;
margin: 2px 0;
font-size: 0.9em;
}

.el-tag {
margin: 2px;
}

.actions {
    flex-direction: column;
}

.actions .el-button {
    width: 100%;
    line-height: 1.2;
    font-size: 12px;
    white-space: nowrap;
}
</style>