<template>
  <div class="menu-container">
    <!-- Меню с кнопками -->
    <div v-if="!activeComponent" class="menu-grid">
      <div 
        v-for="(component, key) in availableComponents"
        :key="key"
        class="menu-card"
        @click="openComponent(key)"
      >
        <div class="card-icon">
          <component :is="componentIcons[key]" />
        </div>
        <h3 class="card-title">{{ component.title }}</h3>
        <p class="card-description">{{ component.description }}</p>
        <el-tag 
          v-if="component.accessLevel" 
          size="small" 
          class="access-tag"
        >
          {{ component.accessLevel }}
        </el-tag>
      </div>
    </div>

    <!-- Контейнер для активного компонента -->
    <div v-else class="component-wrapper">
      <div class="component-header">
        <el-button 
          class="back-button"
          @click="closeComponent"
          circle
        >
        <el-icon><ArrowLeft /></el-icon>
        </el-button>
        <h2 class="component-title">{{ activeComponentMeta.title }}</h2>
      </div>
      
      <div class="component-content">
        <component 
          :is="activeComponent" 
          v-if="!loading"
          class="animated-component"
        />
        <el-skeleton :rows="5" animated v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElButton, ElTag, ElSkeleton } from 'element-plus'
import { 
  ArrowLeft,
  Camera,
  Operation,
  Tickets
} from '@element-plus/icons-vue'

import LogisticDashboard from './functions/LogisticDashboard.vue';
import QrScanner from './functions/QrScanner.vue';
import AccountantDashboard from './functions/AccountantDashboard.vue';

const COMPONENT_META = {
  QrScanner: {
    title: 'QR Сканер',
    description: 'Сканирование qr кодов для приёма груза',
    icon: Camera,
    accessLevel: 'Приёмщик',
    permissions: ['admin_kr7a-J', 'qr']
  },
  LogisticDashboard: {
    title: 'Управление логистикой',
    description: 'Мониторинг и управление транспортными операциями',
    icon: Operation,
    accessLevel: 'Логист',
    permissions: ['logistician', 'admin_kr7a-J']
  },
  AccountantDashboard: {
    title: 'Назначение оплат заявкам',
    description: 'Мониторинг всех заявок и названачение статусов оплат',
    icon: Tickets,
    accessLevel: 'Бухгалтер',
    permissions: ['accountant', 'manager', 'admin_kr7a-J']
  }
};

export default {
  components: {
    ElButton,
    ElTag,
    ElSkeleton,
    ArrowLeft,
    LogisticDashboard,
    QrScanner,
    AccountantDashboard
  },
  data() {
    return {
      loading: false,
      activeComponent: null,
      componentIcons: {
        QrScanner: Camera,
        LogisticDashboard: Operation,
        AccountantDashboard: Tickets
      }
    };
  },
  computed: {
    availableComponents() {
      return Object.fromEntries(
        Object.entries(COMPONENT_META)
          .filter(([key]) => this.hasPermission(key))
      );
    },
    activeComponentMeta() {
      return COMPONENT_META[this.activeComponent] || {};
    }
  },
  methods: {
    async openComponent(component) {
      if (!this.$store.getters.isAuthenticated) {
        ElMessage.error('Требуется авторизация');
        this.$router.push('/login');
        return;
      }
      
      if (!this.hasPermission(component)) {
        ElMessage.error('Недостаточно прав доступа');
        return;
      }

      this.loading = true;
      this.activeComponent = component;
      
      // Имитация загрузки данных
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
    closeComponent() {
      this.activeComponent = null;
    },
    hasPermission(component) {
      const required = COMPONENT_META[component]?.permissions || [];
      return required.includes(this.$store.getters.userRole);
    }
  }
};
</script>

<style scoped>
.menu-container {
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
}

.menu-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.menu-card {
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
  border: 1px solid #ebeef5;
  position: relative;
  overflow: hidden;
}

.menu-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.menu-card:active {
  transform: translateY(-2px);
}

.card-icon {
  font-size: 2.5rem;
  color: #409eff;
  margin-bottom: 1rem;
}

.card-title {
  margin: 0 0 0.5rem;
  color: #303133;
  font-size: 1.2rem;
}

.card-description {
  margin: 0;
  color: #606266;
  font-size: 0.9rem;
  line-height: 1.4;
}

.access-tag {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.component-wrapper {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  min-height: 60vh;
}

.component-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  border-bottom: 1px solid #ebeef5;
}

.component-title {
  margin: 0;
  font-size: 1.5rem;
  color: #303133;
}

.back-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.component-content {
  padding: 2rem;
}

.animated-component {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@media (max-width: 768px) {
  .menu-container {
    padding: 10px;
  }
  
  .menu-card {
    padding: 1.5rem;
  }
  
  .component-header {
    padding: 1rem;
  }
  
  .component-title {
    font-size: 1.2rem;
  }
  
  .component-content {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .menu-grid {
    grid-template-columns: 1fr;
  }
  
  .card-icon {
    font-size: 2rem;
  }
  
  .card-title {
    font-size: 1.1rem;
  }
}
</style>