<template>
<div class="container">
    <div>
    <h3>Выберите камеру:</h3>
    <select id="camera-select" v-model="selectedCameraId" @change="onCameraChange">
        <option v-for="camera in cameras" :key="camera.id" :value="camera.id">
        {{ camera.label || `Камера ${camera.id}` }}
        </option>
    </select>
    <button @click="stopScanner" v-if="isScanning">Остановить сканирование</button>
    </div>
    <div id="reader"></div>
    <button @click="startScanner" v-if="!isScanning">Начать сканирование</button>
</div>
</template>

<script>
// import { ElMessage } from 'element-plus'
import { Html5Qrcode } from "html5-qrcode";
import axios from "axios";

export default {
data() {
    return {
    qrScanner: null,
    isScanning: false,
    decodedText: null,
    cameras: [],
    selectedCameraId: null,
    scannedData: {},
    isProcessing: false,
    };
},
methods: {
    // Запуск сканера с выбранной камерой
    startScanner() {
    if (!this.selectedCameraId) {
        console.error("Камера не выбрана");
        return;
    }

    this.qrScanner = new Html5Qrcode("reader");
    this.isScanning = true;

    const config = { fps: 20, qrbox: 250 };

    this.qrScanner
        .start(
        { deviceId: this.selectedCameraId },
        config,
        (decodedText) => {
            this.handleDecodedText(decodedText);
        },
        (errorMessage) => {
            console.warn("Ошибка сканирования:", errorMessage);
        }
        )
        .catch((err) => {
        console.error("Ошибка при запуске сканера:", err);
        this.isScanning = false;
        });
    },
    // Остановка сканера
    stopScanner() {
    if (this.qrScanner) {
        this.qrScanner.stop().then(() => {
        this.qrScanner.clear();
        this.isScanning = false;
        });
    }
    },
    // Обработка смены камеры
    onCameraChange() {
    console.log("Камера изменена на:", this.selectedCameraId);
    this.restartScanner();
    },
    // Перезапуск сканера
    restartScanner() {
        this.stopScanner();
        this.startScanner();
    },
    // Получение списка камер
    async fetchCameras() {
    try {
        const devices = await Html5Qrcode.getCameras();
        if (devices && devices.length > 0) {
        this.cameras = devices;
        this.selectedCameraId = devices[0].id; // По умолчанию выбираем первую камеру
        } else {
        console.error("Камеры не найдены");
        }
    } catch (err) {
        console.error("Ошибка при получении камер:", err);
    }
    },
    handleDecodedText(decodedText) {
    try {
        if (this.isProcessing) {
            console.warn("Ожидание завершения предыдущей проверки...");
            return;
        }
        this.isProcessing = true;
        
        const jsonData = JSON.parse(decodedText);
        const { id, count, quantity, user_id } = jsonData;

        if (!id || count == null || !quantity || !user_id) {
        alert("Некорректный JSON");
        this.isProcessing = false;
        return;
        }

        this.decodedText = decodedText;
        // payment check
        axios.post("https://test.redbr.ru/api/scan.php", { id, success: 'check' })
            .then(response => {
                if (response.data.status.paid) {
                    if (!this.scannedData[id]) {
                        this.scannedData[id] = { count: quantity, user_id, scanned: new Set() };
                    }

                    if (this.scannedData[id].scanned.has(count)) {
                        alert(`Код с count=${count} для ID=${id} уже отсканирован.`);
                        this.isProcessing = false;
                        return;
                    }

                    this.scannedData[id].scanned.add(count);
                    this.scannedData[id].count -= 1;

                    alert(`Осталось ещё ${this.scannedData[id].count} не отсканированных кодов с id: ${id}`);

                    if (this.scannedData[id].count === 0) {
                        alert(`Отправка данных для ID: ${id}`);
                        this.sendSuccess(id, true);
                    }
                } else {
                    alert('Заявка ещё не оплачена!');
                }
            })
            .catch(error => {
                alert(`Ошибка запроса: ${error.message}`);
            })
            .finally(() => {
                this.isProcessing = false;
            });
        } catch (error) {
            alert(`Ошибка обработки QR-кода: ${error.message}`);
            this.isProcessing = false;
        }
    },
    sendSuccess(id, status) {
    if (status) {
        axios.post("https://test.redbr.ru/api/scan.php", { id, 'success': 'success' })
        .then(response => {
            alert(`Успешно отправлено для ID ${id}:`, response.data);
        })
        .catch(error => {
            alert("Ошибка отправки данных:", error);
        });
    }
    },
},
mounted() {
    this.fetchCameras();
},
};
</script>

<style scoped>
.container {
display: block;
text-align: center;
}
button {
padding: 10px 20px;
font-size: 16px;
margin: 10px;
cursor: pointer;
}
#reader {
margin-left: auto;
margin-right: auto;
margin-top: 1vh;
text-align: center;
}
video {
margin-left: auto;
margin-right: auto;
width: 100% !important;
}
#qr-shaded-region {
margin-left: auto;
margin-right: auto;
}
</style>