<template>
  <router-view />
</template>

<style>
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
input {
  margin: 10px 0;
  padding: 10px;
  width: 200px;
}
button {
  padding: 10px 20px;
  font-size: 16px;
}
body {
  background-color: white;
  text-align: center;
}
</style>
