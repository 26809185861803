<template>
  <div class="login-container">
    <div class="auth-wrapper">
      <div class="auth-header">
        <img src="https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://redbr.ru/&size=128" class="logo" alt="Company Logo">
        <h1 class="title">Вход</h1>
        <p class="subtitle">Для доступа к платформе введите ваши учетные данные</p>
      </div>

      <el-form 
        :model="form" 
        @submit.prevent="handleLogin"
        class="auth-form"
      >
        <el-form-item>
          <el-input
            v-model="form.username"
            placeholder="Введите ваш логин"
            size="large"
          >
            <template #prefix>
              <el-icon><User /></el-icon>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-input
            v-model="form.password"
            type="password"
            placeholder="Введите пароль"
            size="large"
            show-password
          >
            <template #prefix>
              <el-icon><Lock /></el-icon>
            </template>
          </el-input>
        </el-form-item>

        <el-button 
          native-type="submit" 
          type="primary" 
          size="large" 
          class="submit-btn"
          :loading="loading"
        >
          Войти в систему
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { User, Lock } from '@element-plus/icons-vue'

export default {
  components: { User, Lock },
  data() {
    return {
      loading: false,
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    async handleLogin() {
      if (!this.form.username || !this.form.password) {
        return ElMessage.error('Заполните все поля')
      }

      this.loading = true
      
      try {
        const response = await fetch('https://test.redbr.ru/api/login.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(this.form)
        })

        const data = await response.json()
        
        if (data.success) {
          this.$store.commit('setUser', data.user)
          this.$store.commit('setRole', this.form.username)
          this.$router.push('/app')
        } else {
          ElMessage.error(data.message || 'Ошибка авторизации')
        }
      } catch (error) {
        ElMessage.error('Ошибка соединения с сервером')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  padding: 20px;
}

.auth-wrapper {
  background: white;
  padding: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 440px;
}

.auth-header {
  text-align: center;
  margin-bottom: 2rem;
}

.logo {
  height: 60px;
  margin-bottom: 1.5rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #7f8c8d;
  font-size: 0.9rem;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

:deep(.el-input__prefix) {
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.submit-btn {
  width: 100%;
  margin-top: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

@media (max-width: 480px) {
  .auth-wrapper {
    padding: 1.5rem;
    border-radius: 0.75rem;
  }

  .title {
    font-size: 1.25rem;
  }

  .subtitle {
    font-size: 0.8rem;
  }
}
</style>